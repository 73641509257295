
document.addEventListener('DOMContentLoaded', function () {
    const menu = document.querySelector('#sub-menu');
    const btnSubmenu = document.querySelector('#btn-sub-menu');
    if(!menu || !btnSubmenu) return;
    btnSubmenu.addEventListener('click', function (e) {
        menu.classList.toggle('hidden');
    });
});

